// PROD
// module.exports = {
//   APP_NAME: "La Decora",
//   API: "https://api-admin-prod.ladecora.com.br",
//   BUCKET_URL: "https://s3.amazonaws.com/ladecora.platform",
//   PRODUCTION: true,
// };

// DEV
module.exports = {
  APP_NAME: "La Decora",
  API: "https://api-admin-dev.ladecora.com.br",
  // API: "http://localhost:8080",
  BUCKET_URL: "https://s3.amazonaws.com/dev-ladecora.platform",
  PRODUCTION: false,
};
