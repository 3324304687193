import { API } from "../config";
import { getCookie } from "../actions/auth";
import { requestWrapper } from "../utils/api";
export const listAllSuppliers = () => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/mobi/suppliers`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getMobiFilters = () => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/mobi/filters`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getAllStyles = () => {
  const token = getCookie("token");

  return fetch(`${API}/mobi/styles`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getAllColors = () => {
  const token = getCookie("token");

  return fetch(`${API}/mobi/colors`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getAllTypologies = () => {
  const token = getCookie("token");

  return fetch(`${API}/mobi/typologies`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const addStyle = (name) => {
  const token = getCookie("token");

  return fetch(`${API}/mobi/style`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
    body: JSON.stringify({
      name,
    }),
  })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response;
    })
    .then((response) => {
      return response.json();
    });
};

export const addColor = (name) => {
  const token = getCookie("token");

  return fetch(`${API}/mobi/color`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
    body: JSON.stringify({
      name,
    }),
  })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response;
    })
    .then((response) => {
      return response.json();
    });
};

export const addTypology = (name) => {
  const token = getCookie("token");

  return fetch(`${API}/mobi/typology`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
    body: JSON.stringify({
      name,
    }),
  })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response;
    })
    .then((response) => {
      return response.json();
    });
};

export const uploadPicture = (file, mobiId) => {
  const token = getCookie("token");
  const formData = new FormData();
  if (file) {
    formData.append("file", file);
  }

  return requestWrapper(
    fetch(`${API}/mobi/upload/${mobiId}`, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const uploadFile = (file, mobiId) => {
  const token = getCookie("token");
  const formData = new FormData();
  if (file) {
    formData.append("file", file);
  }

  return requestWrapper(
    fetch(`${API}/mobi/upload/file/${mobiId}`, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
export const deleteBlockFile = (mobiId) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/mobi/${mobiId}/deleteblock`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const listAllProducts = (data) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/mobi/filter`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const listAllProducts_deprecated = (data) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/mobi/filter_deprecated`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const createProduct = (product) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/mobi/product`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(product),
    })
  );
};

export const updateProduct = (id, product) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/mobi/product/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(product),
    })
  );
};

export const deleteProduct = (id, product) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/mobi/product/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(product),
    })
  );
};

export const getRecommendedMobis = (data) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/mobi/recommended`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};
export const checkSkuEndNameMobi = (data) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/mobi/check`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const searchProjectsWithMobi = (idMobi) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/mobi/search-projects-mobi/${idMobi}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
